.pl {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -8rem;
}

.pl-texts {
  padding-top: 8rem;
  width: 80%;
}

.pl-title {
  font-size: 50px;
  font-weight: 600;
  margin-top: -1em;
  margin-bottom: 1em;
}

.pl-desc {
  margin: 20px 0px;
}

.pl-list1 {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pl-list11 {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pl-list4 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pl-list22 {
  width: 57%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pl-list2 {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pl-list3 {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.pl-list3 > * {
  width: 400px;  
}

.product {
  width: 100%;
}

.portfolio {
  width: 30%;
  height: 30%;
  padding: 0.5em;
  @media screen and (max-width: 480px) {
    margin: 1em auto;
    width: 80%
  }
}

@media screen and (max-width: 480px) {
  .pl {
    padding: 10px;
  }

  .pl-texts {
    width: 100%;
    margin-top: 5em;
  }

  .pl-desc {
    display: none;
  }

  .pl-title {
    margin-top: 1em;
  }
}

@media screen and (max-width: 600px) {
  .pl-texts {
    margin-top: 5em;
  }
}
