.p {
  width: 20%;
  margin: 20px 10px;
  /* border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 0px 0px; */
  /* overflow: hidden; */
}

.pl-list11 .p:nth-child(1) {
  width: 40%;
}

.pl-list11 .p:nth-child(2) {
  width: 40%
}

.pl-list22 .p:nth-child(1) {
  width: 24.5%;
}

.pl-list22 .p:nth-child(2) {
  width: 30%;
}

.pl-list4 .p:nth-child(1) {
  width: 35%;
  height: 20%;
  margin: 0 auto;
}

.p-browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.p-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: lightgrey;
}

.p-circle:nth-child(1) {
  background-color: red;
}

.p-circle:nth-child(3) {
  background-color: green;
}

.p-img {
  width: 100%;
}


@media screen and (max-width: 920px) {
  .p {
    width: 70%;
    margin: 1em auto;
  }

  .pl-list2 .p:nth-child(1) {
    width: 100%
  }

  .pl-list2 .p:nth-child(2) {
    width: 100%
  }

  .pl-list11 .p:nth-child(1) {
    width: 100%
  }
  
  .pl-list11 .p:nth-child(2) {
    width: 100%
  }
  
  .pl-list22 .p:nth-child(1) {
    width: 70%;
  }
  
  .pl-list22 .p:nth-child(2) {
    width: 70%;
  }

  .pl-list4 .p:nth-child(1) {
    width: 70%;
  }
}

@media screen and (min-width: 920px) {
  .pl-list2 .p:nth-child(1) {
    width: 40%;
    height: 90%;
  }

  .pl-list2 .p:nth-child(2) {
    width: 40%;
    height: 90%;
  }
}